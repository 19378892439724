<template>
  <v-dialog
    :value="value"
    @click:outside="$emit('input', false)"
    @keydown.esc="$emit('input', false)"
    scrollable
    width="1200px"
  >
    <v-card :loading="loading">
      <v-card-title class="text-subtitle-1 accent">
        {{ tagGroupId ? "Editar" : "Adicionar" }} Grupo de Tags
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-6 py-7">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <!-- Descrição -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="tagGroup.descricao"
                label="Descrição"
                dense
              ></v-text-field>
            </v-col>

            <!-- Tipo -->
            <v-col cols="12" md="3">
              <v-select
                v-model="tagGroup.tipo"
                :items="tagGroupTypes"
                label="Tipo"
                dense
                required
                :rules="rules.required"
              ></v-select>
            </v-col>

            <!-- Dashboard -->
            <v-col cols="12" md="3">
              <v-text-field dense readonly value="Dashboard:">
                <template v-slot:append>
                  <v-switch
                    v-model="tagGroup.dashboard"
                    class="mt-0 pt-0"
                    hide-details
                  >
                  </v-switch>
                </template>
              </v-text-field>
            </v-col>

            <!-- Data Inicio -->
            <v-col cols="12" md="3">
              <DataField
                label="Data Início"
                :data_sync.sync="tagGroup.data_inicio"
                required
                :rules="rules.required"
              />
            </v-col>

            <!-- Data Fim -->
            <v-col cols="12" md="3">
              <DataField
                label="Data Fim"
                :data_sync.sync="tagGroup.data_fim"
                required
                :rules="rules.required"
              />
            </v-col>

            <!-- Hora Início -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="tagGroup.hora_inicio"
                label="Hora Início"
                type="time"
                dense
                required
                :rules="rules.required"
              ></v-text-field>
            </v-col>

            <!-- Hora Fim -->
            <v-col cols="12" md="3">
              <v-text-field
                v-model="tagGroup.hora_fim"
                label="Hora Fim"
                type="time"
                dense
                required
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="px-6 py-3">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="white--text"
          :disabled="!valid"
          :loading="loading"
          @click="saveTagGroup"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataField from "@/components/fields/DataField.vue";
import api from "@/api/axios_service";

export default {
  name: "DialogAddEditTagGroup",

  components: {
    DataField,
  },

  props: {
    value: {
      type: Boolean,
    },
    tagGroupId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      valid: true,
      tagGroup: {
        description: "",
        dashboard: false,
        tipo: "",
        data_inicio: "",
        data_fim: "",
        hora_inicio: "",
        hora_fim: "",
      },
      rules: {
        required: [(v) => !!v || "Campo obrigatório"],
      },
      tagGroupTypes: ["palestra", "pit"],
    };
  },

  methods: {
    async saveTagGroup() {
      this.loading = true;
      try {
        if (this.$refs.form.validate()) {
          if (this.tagGroupId) {
            await api.put(`/tags-groups/${this.tagGroupId}`, this.tagGroup);
          } else {
            await api.post("/tags-groups", this.tagGroup);
          }
          this.$emit("input", false);
          this.$emit("refresh");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    if (this.tagGroupId) {
      this.loading = true;

      const { data } = await api.get(`/tags-groups/${this.tagGroupId}`);
      this.tagGroup = data.result;

      this.loading = false;
    }
  },
};
</script>

<style></style>
